import React from 'react';

import global from '../../../common/components/GlobalStyle.module.css';
import FamilyIcon from '../../../common/components/icons/FamilyIcon';
import PersonIcon from '../../../common/components/icons/PersonIcon';
import ShoppingCartIcon from '../../../common/components/icons/ShoppingCartIcon';
import { formatDate } from '../../../common/utils/DateFunctions';

const GridRow = ({ vendor, onAddVendorPerson, onManageUserGroups, onAddShoppingCart }) => (
  <tr>
    <td>{vendor.VendorName}</td>
    <td>{vendor.FirstName}</td>
    <td>{vendor.LastName}</td>
    <td>{vendor.GroupsAssigned > 0 ? 'Yes' : 'No'}</td>
    <td>{vendor.ExpirationDate ? formatDate(vendor.ExpirationDate) : ''}</td>
    <td>{vendor.HasPaid ? 'Yes' : 'No'}</td>
    <td style={{ float: 'right' }}>
      {vendor.UserAccountId &&
        <>
          <button className={global.IconButtonMargin}
            type="button"
            disabled={vendor.CartItemCount > 0}
            onClick={() => onAddShoppingCart(vendor)}>
            <ShoppingCartIcon
              toolTipText='Add Shopping Cart Item'
              showCartCount={vendor.CartItemCount > 0}
              cartCount={vendor.CartItemCount}
            />
          </button>
          <button className={global.IconButtonMargin}
            type="button"
            onClick={() => onManageUserGroups(vendor)}>
            <FamilyIcon toolTipText='Manage User Groups' />
          </button>
        </>
      }
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onAddVendorPerson(vendor)}>
        <PersonIcon toolTipText='Select Vendor Person' />
      </button>

    </td>
  </tr>
);

const ManageVendorsGridLarge = ({ data, isLoading, onAddVendorPerson, onManageUserGroups, onAddShoppingCart }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Vendor</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Groups Assigned</th>
        <th>Expiration Date</th>
        <th>Paid?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((vendor, i) => (
            <GridRow
              key={i}
              vendor={vendor}
              onAddVendorPerson={onAddVendorPerson}
              onManageUserGroups={onManageUserGroups}
              onAddShoppingCart={onAddShoppingCart}
            />
          )) : (
            <tr>
              <td colSpan="2">No Vendors</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default ManageVendorsGridLarge;