import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';
import useForm from "../../../common/utils/UseForm";
import useVendorManagementData from '../../state/vendorManagement/UseVendorManagementData';

import NavLinks from './NavLinks';
import validate from "./ManageVendorsDetailValidation";

const useManageVendorsDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData } = useForm(getInitialFormState(), submitFormCallback, validate);
  const { putVendorManagementInfo } = useVendorManagementData();

  function submitFormCallback() {
    const putVendorManagementInfoPromise =
      putVendorManagementInfo(location.state.vendor.VendorId, formState.memberId, formState.vendorIdCode);

    if (putVendorManagementInfoPromise ?? false) {
      putVendorManagementInfoPromise.then((newState) => {
        if (newState ?? false) {
          navigate(NavLinks.MANAGE_VENDORS);
        }
      }).catch((e) => {
        //context error
      });
    }
  }

  const onBack = () => {
    navigate(NavLinks.MANAGE_VENDORS);
  }

  useEffect(() => {
    debugger;
    if (location.state?.vendor) {
      const vendor = location.state.vendor;
      setFormData({
        ...formState,
        memberId: vendor.MemberId || '',
        vendorIdCode: vendor.VendorIdCode || ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getInitialFormState() {
    return {
      memberId: '',
      vendorIdCode: ''
    }
  }

  return {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBack
  }
}
export default useManageVendorsDetail;