export const localValidate = (formState) => {
  let errors = {};

  debugger;

  if (formState.memberId.trim() === '') {
    errors.memberId = 'Please enter a Member ID';
  }

  if (formState.vendorIdCode.trim() === '') {
    errors.vendorIdCode = 'Please enter a Vendor ID Code';
  } else if (formState.vendorIdCode.length < 20) {
    errors.vendorIdCode = 'Vendor ID Code must be 20 characters long';
  }

  return errors;
};

const ManageCartsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ManageCartsValidation;